import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/RegistroActividad.css'; // Asegúrate de tener este archivo para estilos personalizados
import 'bootstrap/dist/css/bootstrap.min.css';

const RegistroActividad = () => {
    const [plantaciones, setPlantaciones] = useState([]);
    const [selectedPlantacion, setSelectedPlantacion] = useState('');
    const [formData, setFormData] = useState({
        actividad: '',
        gasto: '',
        medicamento: '',
        costo_medicamento: '',
        fecha: '',
    });
    const [actividades, setActividades] = useState([]);

    useEffect(() => {
        const fetchPlantaciones = async () => {
            try {
                const response = await axios.get('https://back-96we.onrender.com/api/plantaciones');
                setPlantaciones(response.data);
            } catch (error) {
                console.error('Error al obtener plantaciones:', error);
            }
        };
        fetchPlantaciones();
    }, []);

    useEffect(() => {
        const fetchActividades = async () => {
            if (selectedPlantacion) {
                try {
                    const response = await axios.get(`https://back-96we.onrender.com/api/actividades_cosecha/${selectedPlantacion}`);
                    setActividades(response.data);
                } catch (error) {
                    console.error('Error al obtener actividades:', error);
                }
            }
        };
        fetchActividades();
    }, [selectedPlantacion]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePlantacionChange = (e) => {
        setSelectedPlantacion(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://back-96we.onrender.com/api/actividades_cosecha', {
                ...formData,
                plantacion_id: selectedPlantacion,
            });
            setActividades([...actividades, response.data]);
            setFormData({
                actividad: '',
                gasto: '',
                medicamento: '',
                costo_medicamento: '',
                fecha: '',
            });
        } catch (error) {
            console.error('Error al registrar la actividad:', error);
        }
    };

    return (
        <div className="registro-actividad-container">
            <div className="registro-actividad-form-container">
                <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                    <h2>Registrar Actividad</h2>
                    <div className="form-group">
                        <select className="form-control" value={selectedPlantacion} onChange={handlePlantacionChange} required>
                            <option value="">Selecciona una variedad</option>
                            {plantaciones.map((plantacion) => (
                                <option key={plantacion.id} value={plantacion.id}>
                                    {`${plantacion.nombre_variedad} - Siembra: ${new Date(plantacion.fecha_cosecha).toLocaleDateString('es-ES')}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            name="actividad"
                            onChange={handleChange}
                            placeholder="Actividad"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            name="gasto"
                            onChange={handleChange}
                            placeholder="Gasto"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            name="medicamento"
                            onChange={handleChange}
                            placeholder="Medicamento"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="form-control"
                            name="costo_medicamento"
                            onChange={handleChange}
                            placeholder="Costo Medicamento"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="date"
                            className="form-control"
                            name="fecha"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Registrar</button>
                </form>
            </div>

            <h3>Actividades Registradas</h3>
            <ul className="list-group">
                {actividades.length > 0 ? (
                    actividades.map((actividad) => (
                        <li key={actividad.id} className="list-group-item">
                            {actividad.actividad} - Gasto: {actividad.gasto} - 
                            Medicamento: {actividad.medicamento || 'N/A'} - 
                            Costo del Medicamento: {actividad.costo_medicamento || 'N/A'} - 
                            Fecha: {new Date(actividad.fecha).toLocaleDateString('es-ES')}
                        </li>
                    ))
                ) : (
                    <p>No hay actividades registradas para esta variedad.</p>
                )}
            </ul>
        </div>
    );
};

export default RegistroActividad;
