import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/RegistrosVariedades.css';

function RegistroVariedades() {
    const [name, setName] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [fechaSiembra, setFechaSiembra] = useState('');
    const [superficiePlantada, setSuperficiePlantada] = useState('');
    const [produccionHistorica, setProduccionHistorica] = useState('');
    const [riesgos, setRiesgos] = useState('');
    const [cuidados, setCuidados] = useState('');
    const [presupuesto, setPresupuesto] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const newVariedad = {
            name,
            descriptcion: descripcion,
            fecha_: fechaSiembra,
            superficie_plantada: superficiePlantada,
            produccion: produccionHistorica,
            riesgos,
            cuidados,
            presupuesto,
        };

        fetch('https://back-96we.onrender.com/api/variedades', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newVariedad),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Variedad registrada:', data);
            // Restablecer los campos del formulario
            setName('');
            setDescripcion('');
            setFechaSiembra('');
            setSuperficiePlantada('');
            setProduccionHistorica('');
            setRiesgos('');
            setCuidados('');
            setPresupuesto('');
        })
        .catch(error => {
            console.error('Error al registrar la variedad:', error);
        });
    };

    return (
        <div className="container d-flex justify-content-center my-4">
            <div className="card p-4 shadow-lg border-0" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', maxWidth: '600px' }}>
                <h2 className="text-center mb-4">Registrar Nueva Variedad de Café</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label>Nombre de la Variedad:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Descripción:</label>
                        <textarea 
                            className="form-control" 
                            value={descripcion} 
                            onChange={(e) => setDescripcion(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Fecha de Siembra:</label>
                        <input 
                            type="date" 
                            className="form-control" 
                            value={fechaSiembra} 
                            onChange={(e) => setFechaSiembra(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Superficie Plantada:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            value={superficiePlantada} 
                            onChange={(e) => setSuperficiePlantada(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Producción Histórica (kg):</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            value={produccionHistorica} 
                            onChange={(e) => setProduccionHistorica(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Riesgos:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            value={riesgos} 
                            onChange={(e) => setRiesgos(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Cuidados:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            value={cuidados} 
                            onChange={(e) => setCuidados(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label>Presupuesto Estimado:</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            value={presupuesto} 
                            onChange={(e) => setPresupuesto(e.target.value)} 
                            required 
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Registrar Variedad</button>
                </form>
            </div>
        </div>
    );
}

export default RegistroVariedades;
