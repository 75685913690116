import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/AnalisisCafe.css';
import RegistroVariedades from './RegistroVariedades';

function AnalisisCafe() {
    const [variedades, setVariedades] = useState([]);
    const [showRegistro, setShowRegistro] = useState(false);

    const loadVariedades = () => {
        fetch('https://back-96we.onrender.com/api/variedades')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => setVariedades(data))
            .catch(error => {
                console.error('Error fetching the varieties:', error);
            });
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Análisis de Café</h1>

            <div className="d-flex justify-content-center mb-3">
                <button className="btn btn-primary me-2" onClick={loadVariedades}>Ver Guía de Variedades</button>
                <button className="btn btn-secondary" onClick={() => setShowRegistro(!showRegistro)}>
                    {showRegistro ? 'Ocultar Registro' : 'Registrar Variedades'}
                </button>
            </div>

            {showRegistro && (
                <div className="mb-4">
                    <RegistroVariedades />
                </div>
            )}

            <div className="list-group">
                {variedades.length > 0 ? (
                    variedades.map(variedad => (
                        <div key={variedad.id} className="list-group-item mb-2 shadow-sm">
                            <h5 className="mb-1"><strong>{variedad.name}</strong></h5>
                            <p className="mb-1"><strong>Descripción:</strong> {variedad.descriptcion}</p>
                            <small><strong>Producción:</strong> {variedad.produccion} kg</small><br />
                            <small><strong>Riesgos:</strong> {variedad.riesgos}</small><br />
                            <small><strong>Cuidados:</strong> {variedad.cuidados}</small><br />
                            <small><strong>Presupuesto estimado:</strong> ${variedad.presupuesto}</small>
                        </div>
                    ))
                ) : (
                    <p className="text-muted text-center">No hay variedades disponibles.</p>
                )}
            </div>
        </div>
    );
}

export default AnalisisCafe;
