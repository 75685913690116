import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSeedling, FaRulerCombined, FaTree, FaMoneyBillWave, FaCalendarAlt, FaTemperatureHigh, FaWater, FaMountain, FaLayerGroup, FaFlask, FaMap, FaThermometerHalf } from 'react-icons/fa';
import '../styles/GestionPlantaciones.css';

const GestionPlantaciones = () => {
    const [formData, setFormData] = useState({
        nombre_variedad: '',
        extension_terreno: '',
        unidad_extension: '',
        cantidad_matas: '',
        produccion_historica: '',
        ganancias_historicas: '',
        fecha_cosecha: '',
        factores_externos: '',
        temperatura_media: '',
        precipitacion: '',
        altitud: '',
        edad_plantas: '',
        tipo_suelo: '',
        fertilizacion: '',
        calidad_suelo: '',
    });

    const [variedades, setVariedades] = useState([]);
    const [rendimientoPromedio, setRendimientoPromedio] = useState(null);
    const [error, setError] = useState(null);
    const [selectedVariedad, setSelectedVariedad] = useState(null);

    useEffect(() => {
        const fetchVariedades = async () => {
            try {
                const response = await axios.get('https://back-96we.onrender.com/api/plantaciones/variedades');
                setVariedades(response.data);
            } catch (error) {
                console.error('Error al obtener variedades:', error);
            }
        };

        fetchVariedades();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: (['extension_terreno', 'cantidad_matas', 'produccion_historica', 
                       'ganancias_historicas', 'temperatura_media', 'altitud', 
                       'edad_plantas'].includes(name) && value !== '')
                ? parseFloat(value)
                : value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        try {
            const response = await axios.post('https://back-96we.onrender.com/api/plantaciones', formData);
            console.log('Datos ingresados correctamente:', response.data);
            setRendimientoPromedio(response.data.rendimiento_promedio);
            setVariedades([...variedades, response.data]); // Actualiza la lista de variedades con la nueva
            // Reiniciar el formulario
            setFormData({
                nombre_variedad: '',
                extension_terreno: '',
                unidad_extension: '',
                cantidad_matas: '',
                produccion_historica: '',
                ganancias_historicas: '',
                fecha_cosecha: '',
                factores_externos: '',
                temperatura_media: '',
                precipitacion: '',
                altitud: '',
                edad_plantas: '',
                tipo_suelo: '',
                fertilizacion: '',
                calidad_suelo: '',
            });
        } catch (error) {
            console.error('Error al ingresar datos:', error);
            setError('Error al ingresar datos. Por favor, revisa la información.');
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toISOString().split('T')[0];
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Registro de Plantaciones</h2>
            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                <div className="row">
                    {Object.keys(formData).map((key, index) => (
                        <div className="col-md-6 mb-3" key={index}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        {key === 'nombre_variedad' && <FaSeedling />}
                                        {key === 'extension_terreno' && <FaRulerCombined />}
                                        {key === 'unidad_extension' && <FaMap />}
                                        {key === 'cantidad_matas' && <FaTree />}
                                        {key === 'produccion_historica' && <FaMoneyBillWave />}
                                        {key === 'ganancias_historicas' && <FaMoneyBillWave />}
                                        {key === 'fecha_cosecha' && <FaCalendarAlt />}
                                        {key === 'factores_externos' && <FaFlask />}
                                        {key === 'temperatura_media' && <FaTemperatureHigh />}
                                        {key === 'precipitacion' && <FaWater />}
                                        {key === 'altitud' && <FaMountain />}
                                        {key === 'edad_plantas' && <FaThermometerHalf />}
                                        {key === 'tipo_suelo' && <FaLayerGroup />}
                                        {key === 'fertilizacion' && <FaFlask />}
                                        {key === 'calidad_suelo' && <FaMap />}
                                    </span>
                                </div>
                                <input
                                    className="form-control"
                                    name={key}
                                    onChange={handleChange}
                                    placeholder={key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                    required
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <button className="btn btn-primary w-100" type="submit">Registrar Plantación</button>
            </form>

            <div className="mt-4">
                <h3>Lista de Variedades</h3>
                <div className="row">
                    {variedades.length > 0 ? (
                        variedades.map((variedad, index) => (
                            <div className="col-md-4 mb-3" key={index}>
                                <div className="card" onClick={() => setSelectedVariedad(variedad)}>
                                    <div className="card-body">
                                        <h5 className="card-title">{variedad.nombre_variedad}</h5>
                                        <p className="card-text">Fecha de Cosecha: {formatDate(variedad.fecha_cosecha)}</p>
                                        <p className="card-text">Producción Histórica: {variedad.produccion_historica}</p>
                                        <p className="card-text">Ganancias Históricas: {variedad.ganancias_historicas}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No hay variedades registradas.</p>
                    )}
                </div>
            </div>

            {selectedVariedad && (
                <div className="card mt-4">
                    <div className="card-body">
                        <h5 className="card-title">Detalles de la Variedad: {selectedVariedad.nombre_variedad}</h5>
                        <p className="card-text"><strong>Fecha de Cosecha:</strong> {formatDate(selectedVariedad.fecha_cosecha)}</p>
                        <p className="card-text"><strong>Producción Histórica:</strong> {selectedVariedad.produccion_historica}</p>
                        <p className="card-text"><strong>Ganancias Históricas:</strong> {selectedVariedad.ganancias_historicas}</p>
                        <p className="card-text"><strong>Factores Externos:</strong> {selectedVariedad.factores_externos}</p>
                        <p className="card-text"><strong>Temperatura Media:</strong> {selectedVariedad.temperatura_media}</p>
                        <p className="card-text"><strong>Altitud:</strong> {selectedVariedad.altitud}</p>
                        <p className="card-text"><strong>Fertilización:</strong> {selectedVariedad.fertilizacion}</p>
                        <p className="card-text"><strong>Calidad del Suelo:</strong> {selectedVariedad.calidad_suelo}</p>
                        <button className="btn btn-secondary" onClick={() => setSelectedVariedad(null)}>Cerrar</button>
                    </div>
                </div>
            )}

{rendimientoPromedio !== null && <p className="mt-3">Rendimiento Promedio: {rendimientoPromedio}</p>}

            {error && <p className="text-danger mt-3">{error}</p>}
        </div>
    );
};

export default GestionPlantaciones;
