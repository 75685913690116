import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import AnalisisCafe from './components/AnalisisCafe';
import RegistroActividad from './components/RegistroActividad';
import ResumenRendimiento from './components/ResumenRendimiento';
import GestionPlantaciones from './components/GestionPlantaciones';
import PresupuestoMedicamentos from './components/PresupuestoMedicamentos';
import CalendarioAplicacion from './components/CalendarioAplicacion';
import Login from './components/Login'; // Importa el componente Login

const App = () => {
    // Verifica si el usuario está autenticado verificando si hay un token en localStorage
    const isAuthenticated = !!localStorage.getItem('token');

    return (
        <Router>
            
            <div>
                <Routes>
                    {/* Ruta del login */}
                    <Route path="/login" element={<Login />} />

                    {/* Rutas protegidas */}
                    <Route 
                        path="/" 
                        element={isAuthenticated ? <Home /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/analisis-cafe" 
                        element={isAuthenticated ? <AnalisisCafe /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/registro-actividad" 
                        element={isAuthenticated ? <RegistroActividad /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/resumen-rendimiento" 
                        element={isAuthenticated ? <ResumenRendimiento /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/plantaciones" 
                        element={isAuthenticated ? <GestionPlantaciones /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/presupuesto-medicamentos" 
                        element={isAuthenticated ? <PresupuestoMedicamentos /> : <Navigate to="/login" />} 
                    />
                    <Route 
                        path="/calendario-aplicacion" 
                        element={isAuthenticated ? <CalendarioAplicacion /> : <Navigate to="/login" />} 
                    />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
