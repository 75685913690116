import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const CalendarioAplicacion = () => {
    const [plantaciones, setPlantaciones] = useState([]);
    const [selectedPlantacion, setSelectedPlantacion] = useState('');
    const [medicamentos, setMedicamentos] = useState([]);
    const [selectedMedicamento, setSelectedMedicamento] = useState('');
    const [fechaAplicacion, setFechaAplicacion] = useState('');
    const [aplicaciones, setAplicaciones] = useState([]);

    useEffect(() => {
        const fetchPlantaciones = async () => {
            try {
                const response = await axios.get('https://back-96we.onrender.com/api/plantaciones');
                setPlantaciones(response.data);
            } catch (error) {
                console.error('Error al obtener plantaciones:', error);
            }
        };
        fetchPlantaciones();
    }, []);

    useEffect(() => {
        const fetchMedicamentos = async () => {
            try {
                const response = await axios.get('https://back-96we.onrender.com/api/medicamentos');
                setMedicamentos(response.data);
            } catch (error) {
                console.error('Error al obtener medicamentos:', error);
            }
        };
        fetchMedicamentos();
    }, []);

    useEffect(() => {
        const fetchAplicaciones = async () => {
            if (selectedPlantacion) {
                try {
                    const response = await axios.get(`https://back-96we.onrender.com/api/aplicaciones/${selectedPlantacion}`);
                    setAplicaciones(response.data);
                } catch (error) {
                    console.error('Error al obtener aplicaciones:', error);
                }
            }
        };
        fetchAplicaciones();
    }, [selectedPlantacion]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`https://back-96we.onrender.com/api/aplicaciones/${selectedPlantacion}`, {
                fecha_aplicacion: fechaAplicacion,
                medicamento_id: selectedMedicamento,
                plantacion_id: selectedPlantacion,  
            });

            const newAplicacion = {
                ...response.data,
                nombre_variedad: response.data.nombre_variedad,
                nombre_medicamento: response.data.nombre_medicamento,
            };

            setAplicaciones([...aplicaciones, newAplicacion]);
            setFechaAplicacion('');
            setSelectedMedicamento('');
            setSelectedPlantacion(''); 
        } catch (error) {
            console.error('Error al registrar la aplicación:', error);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h2 className="text-center mb-4">Registrar Aplicación</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="plantacion" className="form-label">Selecciona una variedad</label>
                                    <select
                                        id="plantacion"
                                        className="form-control"
                                        value={selectedPlantacion}
                                        onChange={e => setSelectedPlantacion(e.target.value)}
                                        required
                                    >
                                        <option value="">Selecciona una variedad</option>
                                        {plantaciones.map(plantacion => (
                                            <option key={plantacion.id} value={plantacion.id}>
                                                {`${plantacion.nombre_variedad} - Siembra: ${new Date(plantacion.fecha_cosecha).toLocaleDateString('es-ES')}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="medicamento" className="form-label">Selecciona un medicamento</label>
                                    <select
                                        id="medicamento"
                                        className="form-control"
                                        value={selectedMedicamento}
                                        onChange={e => setSelectedMedicamento(e.target.value)}
                                        required
                                    >
                                        <option value="">Selecciona un medicamento</option>
                                        {medicamentos.map(medicamento => (
                                            <option key={medicamento.id} value={medicamento.id}>
                                                {medicamento.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fechaAplicacion" className="form-label">Fecha de Aplicación</label>
                                    <input
                                        type="date"
                                        id="fechaAplicacion"
                                        className="form-control"
                                        value={fechaAplicacion}
                                        onChange={e => setFechaAplicacion(e.target.value)}
                                        required
                                    />
                                </div>

                                <button type="submit" className="btn btn-primary w-100">Registrar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5">
                <h3 className="text-center mb-3">Aplicaciones Registradas</h3>
                <ul className="list-group">
                    {aplicaciones.length > 0 ? (
                        aplicaciones.map(aplicacion => (
                            <li key={aplicacion.id} className="list-group-item">
                                <strong>Variedad:</strong> {aplicacion.nombre_variedad || "N/A"} 
                                <strong> - Medicamento:</strong> {aplicacion.nombre_medicamento || "N/A"} 
                                <strong> - Fecha:</strong> {new Date(aplicacion.fecha_aplicacion).toLocaleDateString('es-ES')}
                            </li>
                        ))
                    ) : (
                        <p className="text-center">No hay aplicaciones registradas para esta variedad.</p>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default CalendarioAplicacion;
