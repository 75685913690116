import React from 'react';
import './Home.css'; 
import fondoCafe from '../assets/images/fondo1.jpg'; 
import { Link } from 'react-router-dom';
import { Carousel, Card, Container, Row, Col } from 'react-bootstrap';

// Importación de imagenes aquí

import img2 from '../assets/images/carusel2.jpg';
import img3 from '../assets/images/carrusel3.jpg';
import img4 from '../assets/images/macha_hierro.jpg';

const Home = () => {
    console.log("Home renderizado");
  return (
    <div className="home" style={{ 
        backgroundImage: `url(${fondoCafe})`, 
        backgroundSize: 'cover', // Asegura que la imagen cubra el fondo sin deformarse
        backgroundPosition: 'center center', // Centra la imagen
        backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
        backgroundAttachment: 'fixed', // Mantiene la imagen fija al fondo cuando se hace scroll o zoom
        height: '100vh', // Asegura que ocupe toda la pantalla
        padding: '30px', 
        color: 'white' 
    }}>
    

          <h1 className="text-center mb-4">Bienvenido</h1>
          <p className="text-center mb-5">Esta es la pantalla principal donde podrás gestionar tus variedades de café.</p>

          {/* Carrusel de Imágenes de Enfermedades */}
          <Container className="my-4">
              <Carousel interval={5000} pause="hover">

                  <Carousel.Item>
                      <div className="carousel-content">
                          <img src={img2} className="d-block mx-auto carousel-image" alt="Enfermedad 2" />
                          <Carousel.Caption style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
                              <h3>Tiempo de cosecha</h3>
                              <p>---</p>
                          </Carousel.Caption>
                      </div>
                  </Carousel.Item>
                  <Carousel.Item>
                      <div className="carousel-content">
                          <img src={img3} className="d-block mx-auto carousel-image" alt="Enfermedad 3" />
                          <Carousel.Caption style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
                              <h3>Enfermedad: Roya</h3>
                              <p>---</p>
                          </Carousel.Caption>
                      </div>
                  </Carousel.Item>
                  <Carousel.Item>
                      <div className="carousel-content">
                          <img src={img4} className="d-block mx-auto carousel-image" alt="Mancha Hierro" />
                          <Carousel.Caption style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
                              <h3>Mancha Hierro</h3>
                              <p>---</p>
                          </Carousel.Caption>
                      </div>
                  </Carousel.Item>
              </Carousel>
          </Container>

          {/* Sección de acceso a los módulos */}
          <Container>
              <h2 className="text-center my-4">Accede a las secciones de la plataforma</h2>
              <Row xs={1} sm={2} md={3} className="g-4">
                  <Col>
                      <Link to="/analisis-cafe" style={{ textDecoration: 'none' }}>
                          <Card bg="light" text="dark" className="h-100">
                              <Card.Body>
                                  <Card.Title>Análisis de Café</Card.Title>
                                  <Card.Text>
                                      Realiza análisis detallados de las variedades de café para optimizar su rendimiento y calidad.
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                      </Link>
                  </Col>
                  <Col>
                      <Link to="/registro-actividad" style={{ textDecoration: 'none' }}>
                          <Card bg="light" text="dark" className="h-100">
                              <Card.Body>
                                  <Card.Title>Registro de Actividad</Card.Title>
                                  <Card.Text>
                                      Registra y monitorea todas las actividades realizadas en la finca para mantener un seguimiento preciso.
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                      </Link>
                  </Col>
                  <Col>
                      <Link to="/plantaciones" style={{ textDecoration: 'none' }}>
                          <Card bg="light" text="dark" className="h-100">
                              <Card.Body>
                                  <Card.Title>Administración de Plantaciones</Card.Title>
                                  <Card.Text>
                                      Gestiona la información y el control de las plantaciones de café en diferentes lotes.
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                      </Link>
                  </Col>
                  <Col>
                      <Link to="/presupuesto-medicamentos" style={{ textDecoration: 'none' }}>
                          <Card bg="light" text="dark" className="h-100">
                              <Card.Body>
                                  <Card.Title>Presupuesto de Medicamentos</Card.Title>
                                  <Card.Text>
                                      Consulta y administra el presupuesto estimado para medicamentos y tratamientos en cada variedad.
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                      </Link>
                  </Col>
                  <Col>
                      <Link to="/calendario-aplicacion" style={{ textDecoration: 'none' }}>
                          <Card bg="light" text="dark" className="h-100">
                              <Card.Body>
                                  <Card.Title>Calendario de Aplicación</Card.Title>
                                  <Card.Text>
                                      Lleva el control de las fechas de aplicación de fertilizantes y medicamentos en cada etapa de crecimiento.
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                      </Link>
                  </Col>
                  <Col>
                 
                  </Col>
              </Row>
          </Container>
      </div>
  );
};

export default Home;
