import React from 'react';

const ResumenRendimiento = () => {
    return (
        <div>
            <h2>Resumen de Rendimiento</h2>
            {/* Aquí puedes agregar el resumen del rendimiento de las variedades de café */}
        </div>
    );
};

export default ResumenRendimiento;
