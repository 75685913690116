import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AgregarMedicamento from './AgregarMedicamento';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de que Bootstrap esté importado

const PresupuestoMedicamentos = () => {
    const [plantaciones, setPlantaciones] = useState([]);
    const [selectedPlantacionId, setSelectedPlantacionId] = useState('');
    const [medicamentos, setMedicamentos] = useState([]);
    const [detallePresupuesto, setDetallePresupuesto] = useState([]);
    const [costoTotalEstimado, setCostoTotalEstimado] = useState(0);
    const [rendimientoEstimado, setRendimientoEstimado] = useState(0);
    const [showModal, setShowModal] = useState(false); // Estado para controlar el modal

    useEffect(() => {
        const fetchPlantaciones = async () => {
            try {
                const response = await axios.get('https://back-96we.onrender.com/api/plantaciones');
                setPlantaciones(response.data);
            } catch (error) {
                console.error('Error al obtener plantaciones:', error);
            }
        };

        const fetchMedicamentos = async () => {
            try {
                const response = await axios.get('https://back-96we.onrender.com/api/medicamentos');
                setMedicamentos(response.data);
            } catch (error) {
                console.error('Error al obtener medicamentos:', error);
            }
        };

        fetchPlantaciones();
        fetchMedicamentos();
    }, []);

    const agregarMedicamentoAlPresupuesto = (medicamento) => {
        setDetallePresupuesto((prev) => [...prev, medicamento]);
        calcularTotales([...detallePresupuesto, medicamento]);
    };

    const calcularTotales = (detalle) => {
        const costoTotal = detalle.reduce((acc, item) => acc + item.costo_unitario * item.cantidad, 0);
        const rendimientoTotal = detalle.reduce((acc, item) => acc + item.efectividad_rendimiento, 0);

        setCostoTotalEstimado(costoTotal);
        setRendimientoEstimado(rendimientoTotal);
    };

    const handleCreatePresupuesto = async () => {
        const presupuestoData = {
            plantacion_id: selectedPlantacionId,
            costo_total_estimado: costoTotalEstimado,
            rendimiento_estimado: rendimientoEstimado,
            detalle: detallePresupuesto.map((item) => ({
                medicamento_id: item.id,
                cantidad: item.cantidad,
                costo_medicamento: item.costo_unitario * item.cantidad
            }))
        };

        try {
            const response = await axios.post('https://back-96we.onrender.com/api/presupuestos', presupuestoData);
            alert(`Presupuesto creado exitosamente con ID: ${response.data.id}`);
            setDetallePresupuesto([]);
            setCostoTotalEstimado(0);
            setRendimientoEstimado(0);
        } catch (error) {
            console.error('Error al crear presupuesto:', error);
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center">Presupuesto de Medicamentos</h2>

            {/* Selección de Plantación */}
            <div className="mb-3">
                <label className="form-label">Selecciona una plantación:</label>
                <select value={selectedPlantacionId} onChange={(e) => setSelectedPlantacionId(e.target.value)} className="form-select">
                    <option value="">Selecciona una variedad</option>
                    {plantaciones.map((plantacion) => (
                        <option key={plantacion.id} value={plantacion.id}>
                            {`${plantacion.nombre_variedad} - Siembra: ${new Date(plantacion.fecha_cosecha).toLocaleDateString('es-ES')}`}
                        </option>
                    ))}
                </select>
            </div>

            {/* Listado de Medicamentos */}
            <h3>Medicamentos</h3>
            {medicamentos.length > 0 ? (
                <ul className="list-group mb-3">
                    {medicamentos.map((medicamento) => (
                        <li key={medicamento.id} className="list-group-item d-flex justify-content-between align-items-center">
                            {medicamento.nombre} - {medicamento.costo_unitario} GTQ
                            <button onClick={() => agregarMedicamentoAlPresupuesto({ ...medicamento, cantidad: 1 })} className="btn btn-primary btn-sm">
                                Agregar al Presupuesto
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No hay medicamentos disponibles.</p>
            )}

            {/* Detalle del Presupuesto */}
            <h3>Detalle del Presupuesto</h3>
            {detallePresupuesto.length > 0 ? (
                <ul className="list-group mb-3">
                    {detallePresupuesto.map((item, index) => (
                        <li key={index} className="list-group-item">
                            {item.nombre} - {item.costo_unitario} GTQ x {item.cantidad} unidades
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No hay medicamentos agregados al presupuesto.</p>
            )}

            {/* Totales */}
            <p>Costo Total Estimado: {costoTotalEstimado} GTQ</p>
            <p>Rendimiento Estimado: {rendimientoEstimado}%</p>

            {/* Botón para crear presupuesto */}
            <button onClick={handleCreatePresupuesto} disabled={!selectedPlantacionId || detallePresupuesto.length === 0} className="btn btn-success">
                Crear Presupuesto
            </button>

            {/* Botón para abrir el modal de agregar medicamento */}
            <button onClick={() => setShowModal(true)} className="btn btn-secondary mt-3">
                Agregar Medicamento
            </button>

            {/* Modal para agregar medicamento */}
            {showModal && (
                <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Agregar Medicamento</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <AgregarMedicamento onMedicamentoAdded={() => {
                                    setShowModal(false); // Cierra el modal después de agregar un medicamento
                                    axios.get('https://back-96we.onrender.com/api/medicamentos').then((res) => setMedicamentos(res.data));
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PresupuestoMedicamentos;
