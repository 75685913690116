import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de que Bootstrap esté importado

const AgregarMedicamento = ({ onMedicamentoAdded }) => {
    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [costo_unitario, setCostoUnitario] = useState('');
    const [efectividad_rendimiento, setEfectividadRendimiento] = useState('');
    const [duracion, setDuracion] = useState('');
    const [frecuencia_aplicacion, setFrecuenciaAplicacion] = useState('');
    const [epoca_aplicacion, setEpocaAplicacion] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const nuevoMedicamento = {
            nombre,
            descripcion,
            costo_unitario: parseFloat(costo_unitario),
            efectividad_rendimiento: parseFloat(efectividad_rendimiento),
            duracion: parseInt(duracion),
            frecuencia_aplicacion: parseInt(frecuencia_aplicacion),
            epoca_aplicacion,
        };

        try {
            await axios.post('https://back-96we.onrender.com/api/medicamentos', nuevoMedicamento);
            onMedicamentoAdded();
            setNombre('');
            setDescripcion('');
            setCostoUnitario('');
            setEfectividadRendimiento('');
            setDuracion('');
            setFrecuenciaAplicacion('');
            setEpocaAplicacion('');
        } catch (error) {
            console.error('Error al agregar medicamento:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-4" style={{ backgroundColor: '#e9ecef', borderRadius: '5px' }}>
            <h2 className="text-center">Agregar Medicamento</h2>
            <div className="mb-3">
                <label className="form-label">Nombre:</label>
                <input
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    required
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Descripción:</label>
                <textarea
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                    className="form-control"
                    rows="3"
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Costo por Aplicación:</label>
                <input
                    type="number"
                    step="0.01"
                    value={costo_unitario}
                    onChange={(e) => setCostoUnitario(e.target.value)}
                    required
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Efectividad en Rendimiento (%):</label>
                <input
                    type="number"
                    step="0.01"
                    value={efectividad_rendimiento}
                    onChange={(e) => setEfectividadRendimiento(e.target.value)}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Duración (días):</label>
                <input
                    type="number"
                    value={duracion}
                    onChange={(e) => setDuracion(e.target.value)}
                    required
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Frecuencia de Aplicación:</label>
                <input
                    type="number"
                    value={frecuencia_aplicacion}
                    onChange={(e) => setFrecuenciaAplicacion(e.target.value)}
                    required
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Época de Aplicación:</label>
                <input
                    type="text"
                    value={epoca_aplicacion}
                    onChange={(e) => setEpocaAplicacion(e.target.value)}
                    className="form-control"
                />
            </div>
            <button type="submit" className="btn btn-primary w-100">Agregar Medicamento</button>
        </form>
    );
};

export default AgregarMedicamento;
